@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
/**
 * overflow-y: hidden cam make some header and footer on side panel disappear on ios
 * you have to put transform: translate3d(0, 0, 0); on both to avoid the issue
 */
.side-panel {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 10;
	overflow-y: hidden; // pour empecher le scroll bouncing sur ios et android @see https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/
	//transition: transform 0.2s ease;
	// transform:none au lieu de transform: translateX(0) car sinon les position:fixed ne sont plus fixed
	// voir http://www.redips.net/web/fixed-element-css-transform
	//transform: translateX(-100%);
	height: 100%;
	overscroll-behavior: contain;

	&--rtl {
		//transform: translateX(100%);
	}

	&--pushed {
		//transform: translateX(0);
	}
}
